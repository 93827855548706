import React, {useEffect, useState, useCallback, useMemo} from "react";
import {Button, Flex, Form, Input, Modal, Select, Space, Radio} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";

const AddNewFeed = ({
                        tableid,
                        addNewFeed,
                        rewriteParentFeed,
                        feedParents,
                        newColumns,
                        oldColumns,
                    }) => {
    const [feedParentId, setFeedParentId] = useState(0);
    const [errorsList, setErrorsList] = useState([{error: "Не выбран фид"},]);
    const [showErrors, setShowErrors] = useState(false);

    const [radioBtnValue, setRadioBtnValue] = useState(0);
    const [fieldsReplaceColumn, setFieldsReplaceColumn] = useState([{type: "select"}]);
    const [formReplaceColumnVisible, setFormReplaceColumnVisible] = useState(false);
    const [selectedNewColumns, setSelectedNewColumns] = useState([]);
    const [selectedOldColumns, setSelectedOldColumns] = useState([]);
    const [buttonAddFieldsVisible, setButtonAddFieldsVisible] = useState(false);
    const [enabledButtonAddFields,setEnabledButtonAddFields] = useState(false);

// SAVE NEW FEED
    const [addNewFeedModalVisible, setAddNewFeedModalVisible] = useState(false);
    const [newFeedName, setNewFeedName] = useState("");
    const [loading, setLoading] = useState(false);

// SAVE FEED
    const [feedModalVisible, setFeedModalVisible] = useState(false);

// SAVE REWRITE FEED
    const [rewriteFeedModalVisible, setRewriteFeedModalVisible] = useState(false);

    const showModalSaveNewFeed = () => {
        setFeedModalVisible(false);
        setAddNewFeedModalVisible(true);
    };

    const showModalSaveFeed = () => {
        setFeedModalVisible(true);
        setRadioBtnValue(0);
        setFormReplaceColumnVisible(false);
        setFieldsReplaceColumn([{type: "select"}]);
        setSelectedNewColumns([]);
        setSelectedOldColumns([]);
    };

    const showModalSaveRewriteFeed = () => {
        setFeedModalVisible(false);
        setFeedParentId(0);
        setShowErrors(false);
        setErrorsList([
            {
                error: "Не выбран фид",
            },
        ]);
        setRewriteFeedModalVisible(true);
    };

    const handleAddNewFeedModalOk = async () => {
        setLoading(true);
        await addNewFeed(tableid, newFeedName, selectedNewColumns.map((item) => item.value), selectedOldColumns.map((item) => item.value))
            .then((response) => {
                console.log(response);
                setAddNewFeedModalVisible(false);
                setNewFeedName("");
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const handleRewriteFeedModalOk = async () => {
        if (!errorsList.length) {
            setLoading(true);
            await rewriteParentFeed(feedParentId, selectedNewColumns.map((item) => item.value), selectedOldColumns.map((item) => item.value))
                .then((response) => {
                    console.log(response);
                    setRewriteFeedModalVisible(false);
                    setFeedParentId(0);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        } else {
            setShowErrors(true);
        }
    };

    const handleAddNewFeedModalCancel = () => {
        setAddNewFeedModalVisible(false);
    };

    const handleAddFeedModalCancel = () => {
        setFeedModalVisible(false);
    };

    const handleAddRewriteFeedModalCancel = () => {
        setRewriteFeedModalVisible(false);
    };

    const handleChangeParentFeed = (value) => {
        setErrorsList([]);
        setShowErrors(false);
        setFeedParentId(value);
    };

    const handleRadioBtn = (e) => {
        if (e.target.value === 0) {
            setFormReplaceColumnVisible(false);
        } else {
            setFormReplaceColumnVisible(true);
        }
        setRadioBtnValue(e.target.value);
        setFieldsReplaceColumn([{type: "select"}]);
        setSelectedNewColumns([]);
        setSelectedOldColumns([]);
    };

    const handleSelectedNewColumn = useCallback(
        (value, oldValue) => {
            setSelectedNewColumns((prev) => {
                const newSelected = prev.map((item) =>
                    item.value === oldValue
                        ? newColumns.find((col) => col.value === value)
                        : item
                );
                if (!prev.some((item) => item.value === oldValue)) {
                    newSelected.push(newColumns.find((col) => col.value === value));
                }
                return newSelected.filter(
                    (item) => item && item.value !== undefined && item.value !== ""
                );
            });
        },
        [newColumns]
    );

    const handleSelectedOldColumn = useCallback(
        (value, oldValue) => {
            setSelectedOldColumns((prev) => {
                const newSelected = prev.map((item) =>
                    item.value === oldValue
                        ? oldColumns.find((col) => col.value === value)
                        : item
                );
                if (!prev.some((item) => item.value === oldValue)) {
                    newSelected.push(oldColumns.find((col) => col.value === value));
                }
                return newSelected.filter(
                    (item) => item && item.value !== undefined && item.value !== ""
                );
            });
        },
        [oldColumns]
    );

    const handleAddField = useCallback(() => {
        setFieldsReplaceColumn((prev) => [...prev, {type: "select"}]);
        setSelectedNewColumns((prev) => [...prev, ""]);
        setSelectedOldColumns((prev) => [...prev, ""]);
    }, []);

    const handleRemoveField = useCallback((index) => {
        setFieldsReplaceColumn((prev) => prev.filter((_, i) => i !== index));
        setSelectedNewColumns((prev) => prev.filter((_, i) => i !== index));
        setSelectedOldColumns((prev) => prev.filter((_, i) => i !== index));
    }, []);

    const availableNewColumns = useMemo(() => newColumns.filter((col) => !selectedNewColumns.some((selected) => selected.value === col.value)),
        [newColumns, selectedNewColumns]
    );

    const availableOldColumns = useMemo(() => oldColumns.filter((col) => !selectedOldColumns.some((selected) => selected.value === col.value)),
        [oldColumns, selectedOldColumns]
    );

    useEffect(() => {
        if (availableNewColumns.length !== 0) {
            setButtonAddFieldsVisible(true);
        } else {
            setButtonAddFieldsVisible(false);
        }
        //console.log(selectedNewColumns);
        //console.log(selectedOldColumns);
    }, [availableNewColumns, availableOldColumns]);

    return (
        <>
            <Button
                className="btn"
                onClick={showModalSaveFeed}
                size="large"
                type="primary"
            >
                Сохранить изменения
            </Button>
            <Modal
                open={addNewFeedModalVisible}
                onOk={handleAddNewFeedModalOk}
                confirmLoading={loading}
                onCancel={handleAddNewFeedModalCancel}
                title="Сохранить новый фид"
            >
                <Form.Item>
                    <Input
                        value={newFeedName}
                        placeholder="Укажите название"
                        onChange={(e) => setNewFeedName(e.target.value)}
                    />
                    <div>
                        <br/>
                        <span>Выберите режим сохранения новых столбцов:</span>
                        <Radio.Group onChange={handleRadioBtn} value={radioBtnValue}>
                            <Space direction="vertical">
                                <Radio value={0}>
                                    Сохранить новые столбцы рядом с исходными
                                </Radio>
                                <Radio value={1}>
                                    Сохранить новые столбцы с заменой исходнх
                                </Radio>
                            </Space>
                        </Radio.Group>
                        {formReplaceColumnVisible && (
                            <>
                                {fieldsReplaceColumn.length !== 0 && (
                                    <Flex
                                        justify="space-between"
                                        align="center"
                                        style={{paddingTop: "10px"}}
                                    >
                                        <div>Новые столбцы &nbsp;&nbsp;</div>
                                        <div style={{paddingLeft: "15px"}}>Заменяемые столбцы</div>
                                        <div> </div>
                                    </Flex>
                                )}
                                {fieldsReplaceColumn.map((field, index) => (
                                    <Flex justify="space-between" key={index} align="baseline">
                                        <Form.Item>
                                            <Select
                                                placeholder="Выберите новый столбец"
                                                onChange={(value) =>
                                                    handleSelectedNewColumn(
                                                        value,
                                                        selectedNewColumns[index]?.value
                                                    )
                                                }
                                                style={{
                                                    width: "100%",
                                                    minWidth: "215px",
                                                }}
                                                value={selectedNewColumns[index]?.label}
                                                options={availableNewColumns}
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <Select
                                                placeholder="Выберите столбец для замены"
                                                onChange={(value) =>
                                                    handleSelectedOldColumn(
                                                        value,
                                                        //index
                                                        selectedOldColumns[index]?.value
                                                    )
                                                }
                                                style={{
                                                    width: "100%",
                                                    minWidth: "215px",
                                                }}
                                                value={selectedOldColumns[index]?.label}
                                                options={availableOldColumns}
                                            />
                                        </Form.Item>
                                        <MinusCircleOutlined
                                            onClick={() => handleRemoveField(index)}
                                        />
                                    </Flex>
                                ))}
                                {buttonAddFieldsVisible && (
                                    <div onClick={handleAddField} className="btnline">
                                        <PlusOutlined style={{fontSize: 11}}/>
                                        {fieldsReplaceColumn.length === 0 && (
                                            <div className="text">Добавить заменяемые столбцы</div>
                                        )}
                                        {fieldsReplaceColumn.length !== 0 && (
                                            <div className="text">Добавить еще</div>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </Form.Item>
            </Modal>

            <Modal
                open={rewriteFeedModalVisible}
                onOk={handleRewriteFeedModalOk}
                confirmLoading={loading}
                onCancel={handleAddRewriteFeedModalCancel}
                title="Перезаписать существующий фид"
            >
                {showErrors && <div className="error">Не выбран фид</div>}
                <div className="errormodal_content ">
                    <div className="content">
                        <Select
                            placeholder="Выберите фид"
                            onChange={handleChangeParentFeed}
                            style={{
                                width: "100%",
                            }}
                            value={feedParentId === 0 ? "Выберите фид" : feedParentId}
                            options={feedParents}
                        />
                    </div>
                </div>
                <div>
                    <br/>
                    <span>Выберите режим сохранения новых столбцов:</span>
                    <Radio.Group onChange={handleRadioBtn} value={radioBtnValue}>
                        <Space direction="vertical">
                            <Radio value={0}>Сохранить новые столбцы рядом с исходными</Radio>
                            <Radio value={1}>
                                Сохранить новые столбцы с заменой исходных
                            </Radio>
                        </Space>
                    </Radio.Group>
                    {formReplaceColumnVisible && (
                        <>
                            {fieldsReplaceColumn.length !== 0 && (
                                <Flex
                                    justify="space-between"
                                    align="center"
                                    style={{paddingTop: "10px"}}
                                >
                                    <div>Новые столбцы &nbsp;&nbsp;</div>
                                    <div style={{paddingLeft: "15px"}}>Заменяемые столбцы</div>
                                    <div> </div>
                                </Flex>
                            )}
                            {fieldsReplaceColumn.map((field, index) => (
                                <Flex justify="space-between" key={index} align="baseline">
                                    <Form.Item>
                                        <Select
                                            placeholder="Выберите новый столбец"
                                            onChange={(value) =>
                                                handleSelectedNewColumn(
                                                    value,
                                                    selectedNewColumns[index]?.value
                                                )
                                            }
                                            style={{
                                                width: "100%",
                                                minWidth: "215px",
                                            }}
                                            value={selectedNewColumns[index]?.label}
                                            options={availableNewColumns}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        <Select
                                            placeholder="Выберите столбец для замены"
                                            onChange={(value) =>
                                                handleSelectedOldColumn(
                                                    value,
                                                    //index
                                                    selectedOldColumns[index]?.value
                                                )
                                            }
                                            style={{
                                                width: "100%",
                                                minWidth: "215px",
                                            }}
                                            value={selectedOldColumns[index]?.label}
                                            options={availableOldColumns}
                                        />
                                    </Form.Item>
                                    <MinusCircleOutlined
                                        onClick={() => handleRemoveField(index)}
                                    />
                                </Flex>
                            ))}
                            {buttonAddFieldsVisible && (
                            <div onClick={handleAddField} className="btnline">
                                <PlusOutlined style={{fontSize: 11}}/>
                                {fieldsReplaceColumn.length === 0 && (
                                    <div className="text">Добавить заменяемые столбцы</div>
                                )}
                                {fieldsReplaceColumn.length !== 0 && (
                                    <div className="text">Добавить еще</div>
                                )}
                            </div>
                            )}
                        </>
                    )}
                </div>
            </Modal>

            <Modal
                open={feedModalVisible}
                confirmLoading={loading}
                onCancel={handleAddFeedModalCancel}
                okButtonProps={{style: {display: "none"}}}
                cancelButtonProps={{style: {display: "none"}}}
                title="Создать новый или перезаписать существующий?"
            >
                <Button
                    className="btn"
                    onClick={showModalSaveNewFeed}
                    size="large"
                    type="primary"
                >
                    Сохранить как новый фид
                </Button>
                <br/>
                <Button
                    className="btn"
                    onClick={showModalSaveRewriteFeed}
                    size="large"
                    type="primary"
                >
                    Перезаписать существующий
                </Button>
            </Modal>
        </>
    );
};

export default AddNewFeed;
